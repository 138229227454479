body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root, html, body{
  height: 100%;
}

.MuiPickersDay-root{
  background-color: rgba(0, 255, 0, 0.719) !important;
}

.MuiPickersDay-root.Mui-disabled{
  background-color: transparent !important;
}

.MuiClockNumber-root{
  background-color: rgba(0, 255, 0, 0.331) !important;
}

.MuiClockNumber-root.Mui-disabled {
  background-color: transparent !important;
}

@media only screen and (min-width: 900px)  {
  .booking-datetime-selector .MuiDateCalendar-root{
    transform: scale(1.5);
    margin-top: 200px !important;
    margin-bottom: 200px !important;
  }
  
  .MuiTimeClock-root{
    transform: scale(1.5);
    margin-top: 200px !important;
    margin-bottom: 200px !important;
  }
}
